import { NOTIFY } from "./events";
import { getCartFromStorage, updateCart } from "./utils";

const burgerButton = document.getElementById('burger');
const closeButton = document.getElementById('close');
const menuButton = document.querySelectorAll('.menu-button');
const menu = document.getElementById('menu');
const addToCartButton = document.getElementById('add-to-cart');
const selectDeliveryForm = document.getElementById('select-delivery');
const attachCartForm = document.querySelector('.attach-cart');
const postPayment = document.getElementById('post-payment')
const notificationUl = document.getElementById('messages')


const attachCartToForm = () => {
  const rawCart = localStorage.getItem('cart')
  const input = document.createElement('input')
  input.setAttribute("type", "hidden");
  input.setAttribute("name", "cart");
  input.setAttribute("value", rawCart);

  attachCartForm.appendChild(input);
}

const storeNextDelivery = evt => {
  const storageItem = getCartFromStorage()
  updateCart({
    ...storageItem,
    deliveryTime: evt.target.elements['delivery-time'].value,
    items: {},
    total: 0,
  })
}

const clearCart = ({ status }) => {
  status == 'succeeded' && localStorage.removeItem('cart')
}

const handleNotification = evt => {
  const notificationLi = document.createElement('li')
  notificationLi.classList.add('mx-5', 'p-2', 'mt-2', 'bg-gray-200', 'rounded', 'text-center')
  evt.detail.tags && evt.detail.tags.forEach(t => notificationLi.classList.add(t)) 
  notificationLi.innerText = evt.detail.message
  notificationUl.appendChild(notificationLi)
}

export default () => {
  menuButton.forEach(elt => elt.onclick = () => {
      closeButton.classList.contains('visible')
        ? closeButton.classList.replace('visible', 'hidden')
        : closeButton.classList.replace('hidden', 'visible')

      burgerButton.classList.contains('visible')
        ? burgerButton.classList.replace('visible', 'hidden')
        : burgerButton.classList.replace('hidden', 'visible')

      const classList = menu.classList
      classList.contains('mobile-menu-hidden')
      ? classList.replace('mobile-menu-hidden', 'mobile-menu-active') 
      : classList.replace('mobile-menu-active', 'mobile-menu-hidden') 
  });

  selectDeliveryForm && window.addEventListener('submit', storeNextDelivery)
  attachCartForm && attachCartToForm()
  postPayment && clearCart(postPayment.dataset)
  notificationUl && notificationUl.addEventListener(NOTIFY, handleNotification)
}
