
export const getCartFromStorage = () => {
    const rawCart = localStorage.getItem('cart')
    const cart = rawCart ? JSON.parse(rawCart) : {}
    return cart
}

export const updateCart = newCart => {
  localStorage.setItem('cart', JSON.stringify(newCart))
}

export const formatPrice = centsPrice => {
  const price = parseInt(centsPrice) / 100
  return price.toFixed(2).replace('.', ',')
}

export const getHashParam = () => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get('h')
}

export const getMessageFromError = error => {
  if (error.code === 'UNAVAILABLE') {
    return `"${error.unavailable_dishes[0]}" n'est pas disponible pour le créneau sélectionné`
  }
  return error.message
}