import { loadStripe } from "@stripe/stripe-js";


export const giftPayment = (clientSecret, publicKey) => ({
  hasAcceptedTerms: false,
  stripe: null,
  elements: null,
  paymentError: null,
  sending: false,
  payOnDelivery: false,
  async init() {
    this.stripe = await loadStripe(publicKey);
    if (clientSecret) {
      this.elements = this.stripe.elements({ clientSecret });
      // Set up Stripe.js and Elements to use in checkout form
      const paymentElement = this.elements.create("payment");
      paymentElement.mount("#myCard");
    }
  },
  handleTerms(e) {
    this.hasAcceptedTerms = e.target.checked
  },
  async pay() {
    this.sending = true
    const returnUrl = `${window.location.origin}/bon-cadeau/post_payment`;

    this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: returnUrl,
      },
    }).then((data) => {
      this.paymentError = `Erreur lors du paiement: ${data.error}`
      this.sending = false
    });
  }
  }
)
