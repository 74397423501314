export const ITEM_ADDED = "itemAdded";
export const NOTIFY = "notify";

export const itemAddedEvent = new Event(ITEM_ADDED, {
  bubbles: true,
  composed: true,
});
export const notifyEvent = new Event(NOTIFY, { bubbles: true, composed: true });

export const createNotifyEvent = (message, tags) => {
  const evt = new CustomEvent(NOTIFY, { detail: { message, tags } });
  return evt
};
