import { getHashParam } from "./utils";
import { getHeaders } from "./utils/api";
import { loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/browser";


export const shipping = (editShipping, addressSelected) => ({
  editShipping,
  addressSelected,
  canValidate: null,
  init () {
    this.refreshCanValidate()
  },
  refreshCanValidate() {
    const isAddressSelected = Array.from(
      document.querySelectorAll('input[name="shipping_address_id"], input[name="spot_id"]')
    ).some(e => e.checked)
    this.canValidate = this.editShipping || isAddressSelected
  },
  addAddress() {
    this.editShipping = true
    this.addressSelected = false
    document.querySelectorAll('input[name="shipping_address_id"], input[name="spot_id"]').forEach(e => { e.checked = false })
    this.refreshCanValidate()
  },
  selectAddress(e) {
    e.target.id === 'shipping_address_id' && 
      document.querySelectorAll('input[name="spot_id"]').forEach(e => { e.checked = false })
    e.target.id === 'spot_id' && 
      document.querySelectorAll('input[name="shipping_address_id"]').forEach(e => { e.checked = false })
    this.editShipping = false
    this.addressSelected = e.target.checked
    this.refreshCanValidate()
  }
})

export const stripeHandler = (clientSecret, publicKey, hasConfirmedAge, orderAmount) => ({
  orderAmount,
  hasConfirmedAge,
  hasAcceptedTerms: false,
  canPay: false,
  stripe: null,
  elements: null,
  paymentError: null,
  sending: false,
  payOnDelivery: false,
  async init() {
    if (orderAmount > 0) {
      this.stripe = await loadStripe(publicKey);
      if (clientSecret) {
        this.elements = this.stripe.elements({ clientSecret });
        // Set up Stripe.js and Elements to use in checkout form
        const paymentElement = this.elements.create("payment");
        paymentElement.mount("#myCard");
      }
    }
  },
  refreshCanPay() {
    this.canPay = this.hasConfirmedAge && this.hasAcceptedTerms
  },
  handleTerms(e) {
    this.hasAcceptedTerms = e.target.checked
    this.refreshCanPay()
  },
  handleAge(e) {
    this.hasConfirmedAge = e.target.checked
    this.refreshCanPay()
  },
  handlePaymentOnDelivery(e) {
    this.payOnDelivery = e.target.checked
    this.refreshCanPay()
  },
  async pay() {
    this.sending = true
    const hashParam = getHashParam("h");
    const returnPathname = window.location.pathname.replace(
      "payment",
      "post_payment"
    );
    const returnUrl = hashParam
      ? `${window.location.origin}${returnPathname}?h=${hashParam}`
      : `${window.location.origin}${returnPathname}`;
    const changeStatusUrl = hashParam
      ? `${window.location.origin}${window.location.pathname}?h=${hashParam}`
      : `${window.location.origin}${window.location.pathname}?`;
    
    const headers = getHeaders()
    const payload = this.payOnDelivery
      ? { pay_on_delivery: true }
      : this.orderAmount == 0 ? { pay: false } : { pay: true }
    const body = JSON.stringify(payload)
    const res = await fetch(changeStatusUrl, { method: "POST", headers, body });

    if (this.payOnDelivery || this.orderAmount === 0) {
      if (res.status === 200) {
        window.location.href = returnUrl
      } else {
        this.paymentError = 'Paiement à la livraison désactivé'
      }
    } else {
      this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: returnUrl,
        },
      }).then((data) => {
        console.log(data);
        Sentry.captureMessage(`Payment error: ${data.error.message}, ${data.error.charge}`);
        this.paymentError = `Erreur lors du paiement: ${data.error.message}`
        this.sending = false
      });
    }
    }
})

export const changeBillingAddress = () => ({
  async setBillingAddress(e, addressId) {
    if (e.target.checked) {
      const headers = getHeaders()
      const data = { address_id: addressId }
      const res = await fetch(
        `${window.location.pathname}${window.location.search}`,
        {
          method: 'POST',
          headers,
          body: JSON.stringify(data),
        }
      )
      const resJson = await res.json()
      if (resJson.billing_address_changed) {
        window.location.reload()
      }
    }
  }
})
