export const getHeaders = () => {
  const csrftoken = document.querySelector("[name=csrfmiddlewaretoken]").value
  const headers = new Headers({
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken,
  })
  return headers
}


export const checkAndSyncCart = async (deliveryTime, forceSlot) => {
  const headers = getHeaders()
  const body = JSON.stringify({ deliveryTime, force_slot: forceSlot })
  const res = await fetch(
    '/order/sync-cart/',
    {
      method: 'POST',
      headers,
      body,
    }
  )
  const { is_synced: isSynced, cart: syncedCart, errors: apiErrors } = await res.json()
  return { isSynced, syncedCart, apiErrors }
}
