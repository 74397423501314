import { formatPrice } from './utils'
import { getHeaders } from './utils/api';


export const summaryItem = (idx, quantity, unitCentPrice, remainingQuantity) => ({
  idx,
  quantity,
  unitCentPrice,
  remainingQuantity,
  totalLine: formatPrice(unitCentPrice * quantity),
  async _updateItem({ idx, quantity }) {
    const headers = getHeaders()
    const body = JSON.stringify({ idx, quantity })
    try {
      const res = await fetch(
        '/order/cart/update-item-quantity/',
        {
          method: 'POST',
          headers,
          body,
        }
      )
      const { success, cart: { total} } = await res.json()
      return { success, total }
    } catch (error) {
      console.log(error)
    }
  },
  async remove() {
    const { success, total } = await this._updateItem(
      { idx: this.idx, quantity: -1 }
    )
    if (success) {
      this.quantity--;
      this.updateTotalLine(total)
    }
  },
  async add() {
    const { success, total } = await this._updateItem(
      { idx: this.idx, quantity: 1 }
    )
    if (success) {
      this.quantity++;
      this.updateTotalLine(total)
    }
  },
  async deleteItem() {
    const headers = getHeaders()
    const body = JSON.stringify({ idx: this.idx })
    try {
      const res = await fetch(
        '/order/cart/remove-item/',
        {
          method: 'POST',
          headers,
          body,
        }
      )
      const { success, cart: { total} } = await res.json()
      if (success) {
        this.updateTotalLine(total)
        this.$refs.element.remove()
        window.location.assign(`${window.location.origin}${window.location.pathname}`)
      }
    } catch (error) {
      console.log(error)
    }
  },
  updateTotalLine(nextTotal) {
    this.totalLine = formatPrice(this.unitCentPrice * this.quantity)
    this.$dispatch('update-total', { total: `${formatPrice(nextTotal)}`, totalCents: nextTotal })
  },
})

export const summaryCutlery = (quantity, unitCentPrice) => ({
  quantity,
  unitCentPrice,
  totalLine: formatPrice(unitCentPrice * quantity),
  async _updateCutlery({ quantity }) {
    const headers = getHeaders()
    const body = JSON.stringify({ quantity })
    try {
      const res = await fetch(
        '/order/cart/update-cutlery/',
        {
          method: 'POST',
          headers,
          body,
        }
      )
      const { success, cart: { total} } = await res.json()
      return { success, total }
    } catch (error) {
      console.log(error)
    }
  },
  async remove() {
    const { success, total } = await this._updateCutlery({ quantity: -1 })
    if (success) {
      this.quantity--;
      this.updateTotalLine(total)
    }
  },
  async add() {
    const { success, total } = await this._updateCutlery({ quantity: 1 })
    if (success) {
      this.quantity++;
      this.updateTotalLine(total)
    }
  },
  updateTotalLine(nextTotal) {
    this.totalLine = formatPrice(this.unitCentPrice * this.quantity)
    this.$dispatch('update-total', { total: `${formatPrice(nextTotal)}`, totalCents: nextTotal })
  },
})

export const summaryOffer = (idx, quantity, unitCentPrice, remainingQuantity) => ({
  idx,  
  quantity,
  unitCentPrice,
  remainingQuantity,
  totalLine: formatPrice(unitCentPrice * quantity),
  async deleteOffer() {
    const headers = getHeaders()
    const body = JSON.stringify({ idx: this.idx })
    try {
      const res = await fetch(
        '/order/cart/remove-offer/',
        {
          method: 'POST',
          headers,
          body,
        }
      )
      const { success, cart: { total} } = await res.json()
      if (success) {
        this.updateTotalLine(total)
        this.$refs.element.remove()
        window.location.assign(`${window.location.origin}${window.location.pathname}`)
      }
    } catch (error) {
      console.log(error)
    }
  },
  async _updateOffer({ quantity }) {
    const headers = getHeaders()
    const body = JSON.stringify({ idx: this.idx, quantity })
    try {
      const res = await fetch(
        '/order/cart/update-offer-quantity/',
        {
          method: 'POST',
          headers,
          body,
        }
      )
      const { success, cart: { total} } = await res.json()
      return { success, total }
    } catch (error) {
      console.log(error)
    }
  },
  async remove() {
    const { success, total } = await this._updateOffer({ quantity: -1 })
    if (success) {
      this.quantity--;
      this.updateTotalLine(total)
    }
  },
  async add() {
    const { success, total } = await this._updateOffer({ quantity: 1 })
    if (success) {
      this.quantity++;
      this.updateTotalLine(total)
    }
  },
  updateTotalLine(nextTotal) {
    this.totalLine = formatPrice(this.unitCentPrice * this.quantity)
    this.$dispatch('update-total', { total: `${formatPrice(nextTotal)}`, totalCents: nextTotal })
  }
});
